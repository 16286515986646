import { STORAGE_TOKEN_KEY } from '@/constants/auth'

import { canUseDom } from './helper'

export function persistToken(value: any) {
  if (!canUseDom()) return undefined
  window.localStorage.setItem(STORAGE_TOKEN_KEY, JSON.stringify(value))
}

export function retrieveToken<T>(): T | undefined {
  if (!canUseDom()) return undefined
  const raw = window.localStorage.getItem(STORAGE_TOKEN_KEY)
  if (raw) {
    try {
      return JSON.parse(raw) as T
    } catch (e) {
      return undefined
    }
  }
  return undefined
}

export function clearToken(key: string) {
  if (!canUseDom()) return
  window.localStorage.removeItem(key)
}
