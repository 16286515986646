import { canUseDom } from './helper'

export function getDeviceType() {
  if (!canUseDom()) return ''
  const userAgent = navigator.userAgent.toLowerCase()
  if (userAgent.includes('android')) {
    return 'Android'
  } else if (/iphone|ipad|ipod/.test(userAgent)) {
    return 'IOS'
  } else {
    return 'PC'
  }
}
