/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { UserInfo } from '@/models/auth/userInfo'
import { decode } from 'jsonwebtoken'
import Sensors from 'Sensors'

import { canUseDom } from './helper'

class PackSensors {
  sensors?: typeof Sensors
  constructor() {
    if (canUseDom()) {
      this.sensors = require('sa-sdk-javascript')
      this.sensors!.use('PageLoad', {})
      this.sensors!.use('PageLeave', {})
      this.sensors!.init({
        server_url: process.env.NEXT_PUBLIC_SENSOR_PATH,
        send_type: 'ajax',
        is_track_single_page: true,
        show_log: process.env.NEXT_PUBLIC_APP_ENV !== 'prod',
        heatmap: {
          clickmap: 'default',
          scroll_notice_map: 'default',
          collect_tags: {
            div: true,
            li: true,
            p: true,
            span: true,
          },
        },
        //子配置项 true 表示采集,false 表示不采集,未设置的参数取默认值。
        preset_properties: {
          latest_referrer_host: true,
          latest_landing_page: true,
        },
      })
    }
  }
  login(accessToken = '') {
    if (!this.sensors || !accessToken) return
    const user = decode(accessToken) as UserInfo
    if (user.email) {
      this.sensors.registerPage({ is_login: true })
      this.sensors.login(user.email)
    }
  }
  logout() {
    if (!this.sensors) return
    this.sensors.registerPage({ is_login: false })
    this.sensors.logout()
  }
}

const packSensors = new PackSensors()
export default packSensors
