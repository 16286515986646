'use client'

import { STORAGE_TOKEN_KEY } from '@/constants/auth'
import { Token } from '@/models/auth/token'
import { UserInfo } from '@/models/auth/userInfo'
import packSensors from '@/utils/sensor'
import { clearToken, persistToken, retrieveToken } from '@/utils/token'
import { decode } from 'jsonwebtoken'
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'

export interface AuthContextType {
  authenticated: boolean
  token?: Token
  tokenLoaded: boolean
  signIn: (accessToken: string, refreshToken?: string) => void
  signOut: () => void
  basicInfo: UserInfo | null
}

const defaultAuthContextValue: AuthContextType = {
  authenticated: false,
  tokenLoaded: false,
  signOut: () => {},
  signIn: () => {},
  basicInfo: null,
}

const authContext = createContext<AuthContextType>(defaultAuthContextValue)

const useProvideAuth: () => AuthContextType = () => {
  const tokenPersisted = retrieveToken<Token>()
  const [basicInfo, setBasicInfo] = useState<UserInfo | null>(null)
  const [token, setToken] = useState<Token | undefined>(tokenPersisted)
  const [tokenLoaded, setTokenLoaded] = useState(false)

  const signIn = (accessToken: string, refreshToken = tokenPersisted?.refreshToken ?? '') => {
    const token = { accessToken, refreshToken }
    setToken(token)
    persistToken(token)
    packSensors.login(accessToken)
  }

  const signOut = () => {
    setToken(undefined)
    clearToken(STORAGE_TOKEN_KEY)
    packSensors.logout()
  }

  useEffect(() => {
    setToken(tokenPersisted)
    setBasicInfo(tokenPersisted?.accessToken ? (decode(tokenPersisted.accessToken) as UserInfo) : null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenPersisted?.accessToken])

  // Resolve SSR hydration warning
  useEffect(() => {
    setTokenLoaded(true)
  }, [])

  return {
    authenticated: token !== undefined,
    token,
    signIn,
    signOut,
    tokenLoaded,
    basicInfo,
  }
}

export const useAuth = () => useContext(authContext)

export const ProvideAuth: FC<{ children: ReactNode }> = ({ children }) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
