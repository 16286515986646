import { floorFloat, isFloatStringWithLeastDecimal, removeLeadingZeros } from '@/utils/number'
import isNil from 'lodash-es/isNil'
import trim from 'lodash-es/trim'

export function Parse<T>(input: string) {
  try {
    return JSON.parse(input) as T
  } catch (e) {
    return null
  }
}

export const resource = (src: string) => {
  const URL_REGEX = /https?:\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%\-\/]))?/
  return URL_REGEX.test(src) ? src : `${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}${src}`
}

export function chunk<T>(arr: T[], size: number) {
  return arr.reduce((acc: T[][], cur) => {
    const last = acc[acc.length - 1]
    if (!last || last.length === size) {
      acc.push([cur])
    } else {
      last.push(cur)
    }
    return acc
  }, [])
}

/**
 * Please note negative number input is not working
 * @param input: new value to input
 * @param prevInput: fallback to prev input when exceed min or max value
 * @param min: min value accepted
 * @param max: max value accepted
 * @param precision: float number precision, default 2
 */
export function resolvePositiveNumberInput(input: string, prevInput: string, min: number = 0, max: number = Number.MAX_VALUE, precision: number = 2): string {
  if (input === '') return input

  if (Number(input) < min || Number(input) > max) {
    return prevInput
  }

  const value = removeLeadingZeros(input)

  if (isFloatStringWithLeastDecimal(value, precision)) {
    if (parseFloat(value) === 0) {
      return `0.${'0'.repeat(precision)}`
    } else {
      return floorFloat(parseFloat(value), precision)
    }
  }

  return value
}

export function markDuplicateIds<T extends { id: string }, V extends T & { isDuplicated?: boolean }>(arr: T[]) {
  const idCounts = new Map<string, number>()
  for (const data of arr) {
    const count = idCounts.get(data.id) ?? 0
    idCounts.set(data.id, count + 1)
  }

  const markedArr: V[] = []
  for (const data of arr) {
    if (idCounts.get(data.id)! > 1) {
      markedArr.push({ ...data, isDuplicated: true } as V)
    } else {
      markedArr.push(data as unknown as V)
    }
  }

  return markedArr
}

export function sanitize(content?: string) {
  const text = trim(content)
  if (isNil(content) || text === '') return content
  const l = text.length

  if (l > 6) {
    const p = Math.floor((l - 4) / 2)
    const regex = new RegExp(`^(.).{${p}}(.{2}).*(.)$`)
    const replacement = `$1${'*'.repeat(p)}$2${'*'.repeat(l - p - 4)}$3`
    return text.replace(regex, replacement)
  } else {
    return text[0] + '*'.repeat(l - 2) + text[l - 1]
  }
}

/**
 * Resolve full path as next path (eliminate base path)
 * @param routePath
 */
export const resolveAsNextPath = (routePath: string) => {
  const basePath = process.env.NEXT_PUBLIC_BASE_PATH
  if (basePath === '' || basePath === undefined) return routePath

  const regex = new RegExp(`${basePath}`)
  return routePath.replace(regex, '/')
}

/**
 * Resolve Next path as full path (prefix base path)
 * @param routePath
 */
export const resolveAsFullPath = (routePath: string) => {
  const basePath = process.env.NEXT_PUBLIC_BASE_PATH
  if (basePath === '' || basePath === undefined) return routePath

  return basePath + routePath
}

export const resolveAsFullLink = (routePath: string) => {
  return window.location.origin + resolveAsFullPath(routePath)
}

export function canUseDom() {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  return typeof window !== 'undefined' && window.document.createElement
}
